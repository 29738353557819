export const useStoreStreams = defineStore('StoreStreams', {
    state: () => ({
        streams: null,
        error: null,
        filter: {
            fields: {
                stream_ends: {
                    min: new Date(),
                },
            },
        },
    }),
    actions: {
        initStreams () {
            if (this.streams) return Promise.resolve(this.streams);
            return this.getStreams();
        },
        async getStreams () {
            this.error = null;
            ({streams: this.streams, error: this.error} = await useQueryStreams().get(this.filter));
        },
    },
    getters: {
        liveNow () {
            let stream = this.activeStream;
            if (!stream?.stream_ivs_live) return null;
            return stream;
        },
        activeStream () {
            if (this.activeStreams && this.activeStreams.length) {
                return this.activeStreams[0];
            }
            return null;
        },
        activeStreams () {
            return this.filterStreams({status: 'active'});
        },
        upcomingStreams () {
            return this.filterStreams({status: 'upcoming'});
        },
        filterStreams () {
            return ({status, limitTo}) => {
                if (!this.streams) return null;
                let streams = this.streams.filter(stream =>
                    (status === undefined || stream.stream_status === status)
                );
                return limitTo ? streams.slice(0, limitTo) : streams;
            };
        },
        getStream () {
            return (streamSlug) => {
                if (!this.streams) return null;
                return this.streams.find(item => item.stream_slug === streamSlug) || null;
            };
        },
        getStreamChatToken () {
            return (streamSlug) => {
                let stream = this.getStream(streamSlug);
                return stream?.stream_chat_token;
            };
        },
    },
});
