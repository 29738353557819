let initialised = false;

export default function () {

    const nuxtApp = useNuxtApp();

    const init = async function () {
        if (initialised) return true;
        if (!import.meta.client) return false;
        const ivs = await import('amazon-ivs-player');
        nuxtApp.provide('registerIVSTech', ivs.registerIVSTech);
        initialised = true;
        return true;
    };

    return {init};
}
