<template>
    <SectionCardsSwiper
        v-if="lessons?.length"
        id="section-live-replays"
        :padding="padding"
        :color="color"
        heading="Live Replays"
        headingComponent="h2"
        :cards="lessons.map(lesson => ({
            route: useContextLesson().route(lesson, context),
            image: lesson.lesson_image_url,
            heading: lesson.lesson_title,
            imageAlt: 'A thumbnail from a Live Replay video',
            imageAspectRatio: 'aspect-[40/21] object-cover object-center',
        }))"
    ></SectionCardsSwiper>
</template>

<script>
export default {
    name: 'SectionLiveReplays',
    props: {
        lessons: {
            required: false,
            type: Array,
            default: null,
        },
        padding: {
            required: false,
            default: 'py-8 sm:py-8',
            type: String,
        },
        color: {
            required: false,
            type: String,
            default: null,
        },
        context: {
            required: false,
            type: String,
            default: 'home-live-replays',
        },
    },
};
</script>
