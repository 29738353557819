export default function () {

    const entityStream = useEntityStream();

    async function get (filter) {
        let streams = null, error = null;
        try {
            const {data} = await useApi('live').get('streams', filter);
            streams = data.streams.map((stream) => {
                return entityStream.build(stream);
            });
        } catch (e) {
            error = useApiError(e);
        }
        return {streams, error};
    }

    return {get};
}
