export default function () {

    const $config = useRuntimeConfig().public;

    function build (entity) {
        if (!entity) throw Error('No supplied entity.');
        return {
            ...entity,
            stream_route: streamRoute(entity),
            stream_image_url: streamImageUrl(entity),
            stream_started: streamStarted(entity),
            stream_starts_long_date: streamStartsLongDate(entity),
            stream_starts_short_time: streamStartsShortTime(entity),
            stream_ends_long_date: streamEndsLongDate(entity),
            stream_time_from_now: streamTimeFromNow(entity),
        };
    }

    async function get (streamSlug) {
        let stream = null, error = null;
        try {
            const {data} = await useApi('live').get('streams/' + streamSlug);
            stream = build(data.stream);
        } catch (e) {
            error = useApiError(e);
        }
        return {stream, error};
    }

    async function patch (streamSlug, params) {
        let stream = null, error = null;
        try {
            const {data} = await useApi('live').patch('streams/' + streamSlug, params);
            stream = build(data.stream);
        } catch (e) {
            error = useApiError(e);
        }
        return {stream, error};
    }

    const UtilityDates = useUtilityDates();

    function streamRoute (entity) {
        if (!entity.id_stream) return undefined;
        return {
            name: 'live-streamSlug',
            params: {
                streamSlug: entity.stream_slug,
            },
        };
    }

    function streamImageUrl (entity) {
        if (!entity.stream_image) return $config.uploads + `images-general/learn-guitar-and-ukuele-online-with-andy.jpg`;
        return $config.uploads + 'images-stream/' + entity.stream_image;
    }

    function streamStarted(entity) {
        if (!entity.stream_starts) return undefined;
        return entity.stream_starts < new Date();
    }

    function streamStartsLongDate (entity) {
        if (!entity.stream_starts) return undefined;
        return UtilityDates.printDateTime(entity.stream_starts, 'longDate');
    }

    function streamStartsShortTime(entity) {
        if (!entity.stream_starts) return undefined;
        return UtilityDates.printDateTime(entity.stream_starts, 'shortTime');
    }

    function streamEndsLongDate(entity) {
        if (!entity.stream_ends) return undefined;
        return UtilityDates.printDateTime(entity.stream_ends, 'longDate');
    }

    function streamTimeFromNow(entity) {
        if (!entity.stream_starts) return undefined;
        if (new Date(entity.stream_starts) < new Date()) return 'Live NOW!';
        return 'Live ' + UtilityDates.printDateTimeAgo(entity.stream_starts);
    }

    return {build, get, patch};
}
