<template>
    <SectionCardsSwiper
        v-if="cards?.length"
        id="section-streams"
        :padding="padding"
        :color="color"
        :heading="heading"
        :headingColor="headingColor"
        :headingComponent="headingComponent"
        :cards="cards"
    ></SectionCardsSwiper>
</template>

<script>
export default {
    name: 'SectionStreams',
    props: {
        streams: {
            required: false,
            type: Array,
            default: null,
        },
        // Current stream to exclude
        current: {
            required: false,
            type: Object,
            default () {
                return {};
            },
        },
        padding: {
            required: false,
            default: 'py-8 sm:py-8',
            type: String,
        },
        color: {
            required: false,
            type: String,
            default: null,
        },
        heading: {
            required: false,
            type: String,
            default: null,
        },
        headingColor: {
            required: false,
            type: String,
            default: 'text-gray-900',
        },
        headingComponent: {
            required: false,
            type: String,
            default: 'h2',
        },
    },
    computed: {
        cards () {
            if (!this.streams) return [];
            return this.streams
                .filter(stream =>
                    (this.current?.stream_slug !== stream.stream_slug)
                )
                .map((stream) => {
                    return {
                        image: stream.stream_image_url,
                        imageAlt: 'A thumbnail image from an upcoming live stream lesson',
                        route: stream.stream_route,
                        heading: stream.stream_title,
                        excerpt: stream.stream_time_from_now,
                        imageAspectRatio: '40:21',
                    };
                });
        },
    },
};
</script>
